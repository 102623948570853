/* @import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Foldit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');
html, body {overflow-x: hidden !important;width: 100vw !important;}

body {

  /* RECORD STYLING
  background-color: #FFF9ED;
  color: white; */
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: hidden;
  padding: 0.25em;
  /* height: 100%; */
}

/* MAIN COLORS 
BACKGROUND BODY rgb(255, 238, 207) 

OFF BLACK HEADER BACKGROUND rgb(24, 24, 24); 

RED dd1c1a


LOGO COLORS 
background: FFF9ED

pink: F26D7D
faded black: 252525
yellow: FBC78A
orange: EB5A28

/* #ffcb69; */



/* //// NAV BAR / HEADER SECTION //////////////////////////////////// */

#navbar {

  /* RECORD STYLING */
  /* background-color: #F26D7D; */
  /* display: flex;
  justify-content: center;
  padding: 0.5em;
  font-size: 2em;
  color: #FBC78A; */
  /* border-top: 2px solid #FFF9ED;
  border-bottom: 2px solid #FFF9ED; */
  /* width: 100%; */

  display: flex;
  justify-content: center;
  padding: 0.5em;
  font-size: 2em;
  color: white;
}

#inner-navbar {
  padding-bottom: 1em;
  border-bottom: 2px solid #b58afb;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
  top: 0;
}

#navdiv {
  /* margin-top: 2em; */
  text-align: center;
  z-index: 9;
  background-color: black;
}

#nav-logo-div {
  /* RECORD STYLING */
  /* background-color: #FFF9ED;
  color: wheat;
  text-align: center; */


  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2em;
  padding: 0;
  /* background-color: black; */
}

#TV-div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  width: 100%;
  height: 100vh;
  max-height: 30em;
  z-index: -10;
  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@keyframes changeBlogLettersColor {
  0% {
    color: #9600CC;
  }
  25% {
    color: #FF35FE;
  }
  50% {
    color: #479AFF;
  }
  75% {
    color: #F7F7FF;
  }
  100% {
    color: #735fda;
  }
}

.TVlinks {
  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
  color: #6436ae;
  font-family: 'Bruno Ace', cursive;
  position: absolute;
  text-shadow: 2px 2px 4px #735fda;
  text-decoration: none;
}

#TV-link1 {
  margin-right: 19em;
  margin-bottom: 15em;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
}

#TV-link2 {
  margin-left: 7em;
  margin-bottom: 23em;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
}

#TV-link2:hover {
  cursor: pointer;
}

#TV-link3 {
  margin-left: 13.75em;
  margin-bottom: 11em;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
}

#TV-link3:hover {
  cursor: pointer;
}

#TV-link4 {
  margin-right: 15.25em;
  margin-bottom: 1.25em;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
}

#TV-link4:hover {
  cursor: pointer;
}

#TV-link5 {
  margin-top: 1.8em;
  margin-left: 0.25em;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
}

#music-links-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0;
  /* display: block; */
}

.music-links {
  font-size: small;
  margin-left: 1em;
  z-index: 10;
  color: black;
  font-weight: 700;
  text-decoration: none;
}

.music-links:hover {
  text-decoration: underline;
  transition: 0.5s;
}


#social-links-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.social-icons {
  font-size: 1.5em;
  margin: 0.25em;
  z-index: 10;
  color:rgb(0, 0, 0);
}

.social-icons:hover {

  /* RECORD STYLING
  color: #dd1c1a; */

  transform: translateY(-5px);
  cursor: pointer;
  transition: 0.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#live-dates {
  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.live-shows-location {
  padding: 0;
  margin: 0;
}

.date {
  display: inline-block;
  text-decoration: underline;
}

.live-shows-venue {
  margin-bottom: 0;
  margin-top: 1em;
  font-weight: 900;
  text-align: center;
}

#header-vid {
  z-index: -10;
  height: 50em;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#logo {
  /* width: 30vw;
  height: 30vh; */
  width: 7.75em;
  z-index: 10;
}

#logo:hover {
  cursor: pointer;
}

.watch-vids {
  width: 30em;
}

























#nav-logo {
  font-size: 15em;
}

.alink {
  padding: 0.5em;
  font-size: 0.75em;
  text-decoration: none;
  color: white;
}

.alink:hover {
  /* text-decoration: underline; */

  /* RECORD STYLING
  color: #dd1c1a; */

  color:#8a43fc;
  cursor: pointer;
  transition: 0.35s;
}

#social-links {
  margin-top: 1em;
  padding-bottom: 1em;
}

#vid-section {
  margin-top: 4em;
  display: flex;
  justify-content: center;
}

.video-responsive {
  position: relative;
}










/* ///// LIVE SECTION /////////////////////////////////// */

#live-section {

  /* RECORD STYLING
  background-color: #dd1c1a; */

  background-color: #b58afb;
  margin-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

#live-content-container {
  padding: 2em;
  width: 90%;
  text-align: center;
  font-weight: 900;
  color: #252525;

  /* RECORD STYLING
  border-top: 2px solid #dd1c1a; */

  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

#live-text {
  color: white;
}

.live-header {
  font-size: 5em;
  color: #252525;
}

.purple-live-header {
  font-size: 5em;
  color: #FFF9ED;
}

.live-show {
  margin:auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em 1em 0.25em 1em;
  text-transform: uppercase;
  background-color: white;
}

.live-show:hover {

  /* RECORD STYLING
  background-color: rgb(221, 28, 26); */

  background-color: white;
  transition: 0.25s;
}

.tix-link {
  color: black;
  background-color: white;
  padding: 0.25em;
  border: solid 1px black;
  font-size: smaller;
}

.tix-link:hover {
  color: white;
  background-color: black;
  transition: 0.5s;
}

.past-live-show {
  margin:auto;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em 1em 0.25em 1em;
  text-transform: uppercase;
  background-color: gray;
  color:#252525
}

.ticket-button {

  /* RECORD STYLING
  background-color: wheat; */

  background-color: #b58afb;
  border: 2px solid white;
  color: white;
  padding: 1em;
  height: 100%;
}

.ticket-button:hover {

  /* RECORD STYLING
  background-color: rgba(157, 6, 13, 0.545); */

  background-color: #8a43fc;
  cursor: pointer;
  transition: 0.25s;
}

.ticket-link {
  text-decoration: none;
  text-transform: uppercase;
}

.pastshow-ticket-button {
  pointer-events: none;
  background-color: gray;
  border: 2px solid white;
  color: white;
  padding: 1em;
  height: 100%;
}

.ticket-link {
  color: white;
}

.ticket-link:hover {
  color: rgba(119, 56, 255, 0.545);
  transition: 0.25s;
}

.sold-out {
  text-decoration: line-through;
}










/* ///////// BLOG SECTION //////////////////////////////////// */

#blog-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.blog-icon-div {
  text-align: center;
  padding: 0.5em;
}

.blog-social-icons {
  font-size: 2em;
  margin: 1em;
  /* RECORD STYLING
  color: #FBC78A; */
  color: #8a43fc;
}

.blog-social-icons:hover {
  color: white;
  cursor: pointer;
  transition: 0.25s;
}

#right-blog {
  color: white;
  padding: 4em;
}

#left-blog {
  color: white;
  padding: 4em;
}

#middle-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blog-letters {
  animation-name: changeBlogLettersColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition-duration: 2s;
  text-align: center;
  font-size: 10em;
  font-weight: 900;
}

.song-blog {
  border: 2px solid white;
  color: white;
  padding: 1em;
  margin: 1em;
  width: 90%;
  text-align: center;
  display: block;
}

.blog-title {
  font-weight: 900;
  font-size: 3em;
  background-color: white;
  color: black;
  margin: 0;
}

.lyrics-img-section {
  height: 30em;
  display: flex;
}

.true-lyrics-img {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 1em;
  background-color: white;
  overflow: scroll;
}

.meaning-section {
  color: black;
  width: 40%;
  padding: 1em;
  border-right: 2px solid black;
  background-color: white;
  overflow:scroll;
}

.song-img {
  border: 1px solid black;
  align-self: flex-end;
  justify-self: flex-end;
  width: 80%;
  height: 50%;
}





/* ///////////////// CONTACT SECTION ////////////////////////// */


#contact-section {
  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  background-color: white;
  display: flex;
  flex-direction: column;
}

#contact-section-left {
  text-align: center;
  /* width: 50vw; */
}

#inner-left {
  border-bottom: 2px solid black;
  text-align: center;
  /* width: 50vw; */
  /* RECORD STYLING
  border-right: 2px solid #dd1c1a; */
}

#contact-section-right {
  /* width: 50vw; */
  text-align: center;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#contact-logo {
  color: #252525;
  font-size: 5em;
  font-weight: 900;
}

.contact-content {
  color: #252525;
  font-size: 2em;
  font-weight: 900;
}

#contact-email {
  color: #252525;
  font-size: 1.25em;
  font-weight: 900;
  margin-bottom: 2em;
}


#email-input {
  text-align: center;
  width: 20em;
  padding: 0.75em;
}

#email-input:focus {
  outline: none;
}

.contact-btn {
  color: black;
  border: 1px solid rgb(3, 3, 3);
  background-color: white;
  margin: 0.5em;
  padding: 0.5em;
}

.btn:hover {
  background-color: #8a43fc;
  cursor: pointer;
}

.contact-message {
  border-radius: 10px;
  margin-top: 1em;
  text-transform: uppercase;
  color: white;
  font-size: 0.75em;
  background-color: #8a43fc;
  padding: 1em;
}

#modal-x {
  font-size: 1.75em;
}

#modal-x:hover {
  color: #252525;
  cursor: pointer;
  transition: 0.25s;
}

/* /////// MEDIA QUERIES ////////// */

@media screen and (max-width: 570px) {
  #header-vid {
    height: 40em;
  }

  #logo {
    width: 8em;
  }

  #TV-link1 {
    margin-right: 15.25em;
    margin-bottom: 12em;
  }
  
  #TV-link2 {
    margin-left: 5.75em;
    margin-bottom: 18.25em;
  }

  #TV-link3 {
    font-size: 0.88em;
    margin-left: 12.5em;
    margin-bottom: 9.5em;
  }

  #TV-link4 {
  font-size: 0.9em;
  margin-right: 13.5em;
  margin-bottom: 1.25em;
  }

  #TV-link5 {
    font-size: 0.85em;
    margin-left: 0.25em;
  }

  #live-dates {
    font-size: x-small;
  }

  .watch-vids {
    width: 18em;
  }

  .live-shows-location {
    font-size: small;
  }
  
  .live-shows-venue {
    font-size: 1.75em;
  }
}

@media screen and (max-height: 700px) {
  #header-vid {
    height: 33em;
  }

  #TV-div {
    height: 18em;
  }

  #logo {
    width: 5em;
    padding-bottom: 2em;
  }

  .social-icons {
    width: 0.75em;
  }

  #TV-link1 {
    font-size: smaller;
    margin-right: 14.75em;
    margin-bottom: 12em;
  }
  
  #TV-link2 {
    font-size: smaller;
    margin-left: 5.75em;
    margin-bottom: 18.25em;
  }

  #TV-link3 {
    font-size: 0.7em;
    margin-left: 13em;
    margin-bottom: 10.5em;
  }

  #TV-link4 {
    font-size: 0.7em;
  margin-right: 14.75em;
  margin-bottom: 1.25em;
  }

  #TV-link5 {
    font-size: 0.7em;
    margin-left: 0.25em;
  }

  .review-card {
    height: 50%;
  }

  #review-div {
    width: 7em;
  }

  .contact-content {
    font-size: 1em;
  }

  #contact-email {
    font-size: 0.8em;
  }
}


/* ////////// MEDIA QUERIES ///////////////////////////// */

/* @media screen and (max-width: 1110px){
  #right-blog {
    display: none;
  }

  #left-blog {
    display: none;
  }

  .purple-live-header {
    display: none;
  }

  .live-header {
    text-align: center;
    font-size: 2.5em;
  }
}

@media screen and (max-width: 900px){
  #contact-email {
    font-size: 1.1em;
  }

  #contact-logo {
    font-size: 2.5em;
  }

  .contact-content {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 870px) {
  .video-responsive {
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #contact-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #inner-left {
    border-bottom: 2px solid #b58afb;
    border-right: none;
  }

  #contact-section-right {
    padding: 0;
  }
} */

/* @media screen and (max-width: 700px) {
  .live-date {
    font-size: 1.2em;
  }

  .live-venue {
    font-size: 1.2em;
  }

  .ticket-button {
    font-size: smaller;
  }
} */

/* @media screen and (max-width: 650px) {
  .true-lyrics-img {
    font-size: 0.75em;
    overflow: scroll;
  }

  .lyrics-img-section {
    font-size: smaller;
  }

  #contact-email {
    font-size: 0.65em;
  }

  .song-img {
    height: 40%;
  }
} */

/* @media screen and (max-width: 570px) {
  .live-show {
    width: 20em;
    padding: 1em;
  }

  .live-header {
    display: none;
  }
} */

/* @media screen and (max-width: 460px) {
  .song-img {
    width: 100%;
  }


} */

/* @media screen and (max-width: 710px) {
  html {
    width: 100%;
    overflow-x: hidden;
  }
} */


#review-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

#review-div {
  width: 100vw;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: white;

  animation: fade ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.section-header {
  /* margin-bottom: 2em; */
  font-weight: 600;
  font-size: 2.5em;
  color:   #3d5a80;
  /* padding: 1em; */
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

#review-subheading {
  text-align: center;
  color:  #53687E;
  font-family: 'Raleway', sans-serif;
}

.review-card-section {
  display: flex;
  justify-content: space-around;
}

.review-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  color: #293241;
  width: 22.5em;
  height: 22.5em;
  /* border-radius: 10px; */
  /* border-top: 2px solid black;
  border-bottom: 2px solid black; */
  font-family: 'Raleway', sans-serif;
  /* background-color: #6ca1e7; */
  /* margin: 1em; */
}

.review-title {
  color: #00216f;
}

.inner-review-card {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 580px) {
  .review-card-text {
    font-size: 0.65em;
  }

  .review-title {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 400px) {
  .review-card-text {
    font-size: 0.5em;
  }

  .review-title {
    font-size: 1em;
  }
}